import React from "react"
import HeadlessCTA from "../components/headlessCTA"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tag from "../components/tag"
import logoDoodad from "../images/logo-doodad.png"
import CTA from "../components/cta"
import BenefitCard from "../components/BenefitCard"
import FeatureCard from "../components/FeatureCard"
import { graphql, Link } from "gatsby"

import { renderRichText } from "gatsby-source-contentful/rich-text"

import { GatsbyImage } from "gatsby-plugin-image"

import Feature1 from "../images/use-cases/sales 1.png"
import Feature2 from "../images/use-cases/sales 2.png"
import Feature3 from "../images/use-cases/sales 3.png"
import UseCases from "../components/UseCases"
import LogoWall from "../components/LogoWall"

export const query = graphql`
  query($slug: String!) {
    contentfulUseCase(slug: { eq: $slug }) {
      title
      slug
      blurb {
        blurb
      }
      headerImage {
        gatsbyImageData(quality: 100)
      }
      problemImage {
        gatsbyImageData(quality: 100)
      }
      solutionBlurb
      solutionCard1Blurb
      solutionCard1Title
      solutionCard2Blurb
      solutionCard2Title
      solutionCard3Blurb
      solutionCard3Title
      solutionIcons {
        gatsbyImageData(quality: 100)
      }
      solutionTitle
      problemTitle
      problemBlurb {
        raw
      }
    }
  }
`

const UseCasePage = props => {
  console.log(props)
  const useCasePage = props.data.contentfulUseCase

  return (
    <Layout variant="useCase">
      <SEO title={useCasePage.title} description={useCasePage.blurb.blurb} />
      <div className="max-w-screen overflow-x-hidden relative">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-48 pt-24 items-center">
            <div>
              <Tag>Research Automation</Tag>
              <h1
                className="font-bold text-white text-5xl md:text-6xl mt-12 mb-8"
                style={{
                  mixBlendMode: "overlay",
                  fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
                }}
              >
                {useCasePage.title}
              </h1>

              <h2 className="text-white text-lg mb-8 leading-relaxed">
                {useCasePage.blurb.blurb}
              </h2>

              <HeadlessCTA />
            </div>
            <GatsbyImage
              image={useCasePage.headerImage.gatsbyImageData}
              className="-ml-48 -mt-24 hidden lg:block"
            />
          </div>
        </div>
      </div>


      <div className="container mx-auto flex mt-16">
        <div className="flex-1"></div>
        <LogoWall />
        <div className="flex-1"></div>
      </div>

      <img
        src={logoDoodad}
        className="absolute"
        style={{ maxHeight: "650px" }}
      />

      <div
        className="container p-4 mx-auto flex flex-col lg:flex-row text-white
        my-24 bg-white/10 rounded-xl backdrop-blur-lg border border-white/20 items-center"
      >
        <div className="flex-1">
          <GatsbyImage image={useCasePage.problemImage.gatsbyImageData} />
        </div>
        <div className="flex-1 p-6 lg:p-24">
          <div className="text-salmon text-sm uppercase tracking-widest">
            The Problem
          </div>
          <h2 className="font-semibold text-4xl my-12">
            {useCasePage.problemTitle}
          </h2>
          <div className="prose prose-lg text-white/75">
            {renderRichText(useCasePage.problemBlurb)}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6">
        <div className="flex flex-col gap-4 lg:text-center max-w-4xl mx-auto mb-12">
          <div className="text-green-400 text-sm uppercase tracking-widest">
            The Solution
          </div>
          <h2 className="font-bold text-4xl md:text-5xl flex-1 leading-tight text-white z-10">
            {useCasePage.solutionTitle}
          </h2>
          <p className="text-medium-grey text-lg">
            {useCasePage.solutionBlurb}
          </p>
        </div>
        <div className="flex flex-col lg:flex-row gap-2">
          <BenefitCard
            icon={
              <GatsbyImage
                image={useCasePage.solutionIcons[0].gatsbyImageData}
                className="h-24 w-24 -ml-4"
              />
            }
            heading={useCasePage.solutionCard1Title}
            body={useCasePage.solutionCard1Blurb}
          />
          <BenefitCard
            icon={
              <GatsbyImage
                image={useCasePage.solutionIcons[1].gatsbyImageData}
                className="h-24 w-24 -ml-4"
              />
            }
            heading={useCasePage.solutionCard2Title}
            body={useCasePage.solutionCard2Blurb}
          />
          <BenefitCard
            icon={
              <GatsbyImage
                image={useCasePage.solutionIcons[2].gatsbyImageData}
                className="h-24 w-24 -ml-4"
              />
            }
            heading={useCasePage.solutionCard3Title}
            body={useCasePage.solutionCard3Blurb}
          />
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6 my-24">
        <h2 className="text-white text-5xl font-bold mb-12 text-center">
          This is how we help
        </h2>
        <div className="flex flex-col gap-9">
          <FeatureCard
            heading="A search engine for everything"
            body="You can finally close all your browser tabs, because Tactic will search for insights across the whole web including annual company reports, T&Cs, 'about us' pages, LinkedIn, Zoominfo, nestled PDFs, job posts and more."
            direction="left"
            image={Feature1}
          />
          <FeatureCard
            heading="Adjustable reports"
            body="You can be agile with your data, curating reports from your Tactic results in just a few clicks to have the relevant people and keyword insights to hand while you're on a sales call or designing your outbound messaging."
            direction="right"
            image={Feature2}
          />
          <FeatureCard
            heading="Set up the next steps"
            body="You can set up Tactic to automatically trigger account prioritisation within Salesforce, email campaigns and updates to your call list based on any updates to your insights when you re-run your project."
            direction="left"
            image={Feature3}
          />
        </div>
      </div>
      <UseCases hideSection="sales" />
      <CTA />
    </Layout>
  )
}

export default UseCasePage
